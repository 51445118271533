import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import {
    InstagramApplicationConfigInterface,
    InstagramApplicationConfigToken,
} from "@apps/instagram/InstagramApplicationConfig";
import {
    TikTokApplicationConfigInterface,
    TikTokApplicationConfigToken,
} from "@apps/tiktok/TikTokApplicationConfig";
import { akitaDevtools } from "@datorama/akita";
import { AnalyticsEventsFactory } from "@lib/AnalyticsEventsCollector/AnalyticsEventsFactory";
import RxJSAnalyticsEventsCollector from "@lib/AnalyticsEventsCollector/RxJSAnalyticsEventsCollector";
import AnalyticsEventsSender from "@lib/AnalyticsEventsSender/AnalyticsEventsSender";
import ApiClient from "@lib/ApiClient";
import ApiClientWithMetrics from "@lib/ApiClient/ApiClientWithMetrics";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import AppsStatusesProvider from "@lib/AppsStatusesProvider/AppsStatusesProvider";
import FromShopifyCurrencyCurrencyProvider from "@lib/CurrencyProvider/FromShopifyCurrencyCurrencyProvider";
import CustomerInfoFromJsonProvider from "@lib/CustomerInfoProvider/CustomerInfoFromJsonProvider";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import MoneyFormatter from "@lib/Formatters/MoneyFormatter/MoneyFormatter";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import GrowaveConfigurationFromJsonProvider from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationFromJsonProvider";
import GwCurrency from "@lib/GwCurrency/GwCurrency";
import { GwI18n } from "@lib/GwI18n/GwI18n";
import I18nCriticalLoaderFactory from "@lib/GwI18n/I18nCriticalLoaderFactory";
import { I18nApiLoader } from "@lib/GwI18n/loaders/I18nApiLoader/I18nApiLoader";
import HistoryPatcher from "@lib/HistoryPatcher";
import XSSHTMLSanitizer from "@lib/HTMLSanitizer/XSSHTMLSanitizer";
import { SimpleLocalizationInfoProvider } from "@lib/LocalizationInfoProvider/SimpleLocalizationInfoProvider";
import LocationController from "@lib/LocationController/LocationController";
import FakeMoneyExchanger from "@lib/MoneyExchanger/FakeMoneyExchanger";
import LegacyMoneyExchanger from "@lib/MoneyExchanger/LegacyMoneyExchanger";
import { ProductIdFromAdditionalInfoProvider } from "@lib/ProductIdManager/ProductIdFromAdditionalInfoProvider";
import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductIdManager } from "@lib/ProductIdManager/ProductIdManager";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import ReferrerProvider from "@lib/ReferrerProvider";
import Timer from "@lib/timer";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import ShopifyCartApiService from "@modules/cart/api_services/CartService/ShopifyCartApiService";
import { CartQuery } from "@modules/cart/queries/CartQuery";
import { CartService } from "@modules/cart/services/CartService";
import { CartStore } from "@modules/cart/stores/CartStore";
import {
    cartManagerCounterUpdatersContextToken,
    cartManagerItemsUpdatersContextToken,
    cartQueryToken,
    cartServiceToken,
    cartStoreToken,
} from "@modules/cart/tokens";
import CartListener from "@modules/cart/utils/CartListener/CartListener";
import FetchOrXHRPatcherListenerStrategy from "@modules/cart/utils/CartListener/FetchOrXHRPatcherListenerStrategy";
import SendFormListenerStrategy from "@modules/cart/utils/CartListener/SendFormListenerStrategy";
import CartManagerCounterUpdatersContext from "@modules/cart/utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "@modules/cart/utils/CartManager/CartManagerItemsUpdatersContext";
import { ProductsModule } from "@modules/products/ProductsModule";
import { SdkModule } from "@modules/sdk/SdkModule";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { lastValueFrom } from "rxjs";
import { container, instanceCachingFactory } from "tsyringe";

import CheckService from "./apps/login/services/CheckService/CheckService";
import {
    CURRENT_APP_INSTAGRAM,
    CURRENT_APP_QUERY_PARAMETER,
} from "./constants/current_app";
import { GP_GW_REFFERER } from "./constants/get_params";
import { CurrentTokenChecker } from "./CurrentTokenChecker";
import { MinilogLogger } from "./lib/Logger";
import MetricsCollectorService from "./services/MetricsCollectorApiService";
import RefreshTokenService from "./services/RefreshTokenService";
import TranslationsApiService from "./services/TranslationsApiService/TranslationsApiService";
import {
    apiClientToken,
    cartListenerToken,
    commonAuthApiClientToken,
    currencyProviderToken,
    customerInfoProviderToken,
    eventsCollectorAuthApiClientToken,
    eventsCollectorToken,
    fetchAndXhrPatcherToken,
    fileUploaderAuthApiClientToken,
    globalLoggerToken,
    globalProductIdManagerToken,
    growaveConfigurationProviderToken,
    gwI18nToken,
    gwStorefrontAppInfoToken,
    htmlSanitizerToken,
    i18nCriticalLoaderFactoryToken,
    localStorageToken,
    localizationInfoProviderToken,
    locationControllerToken,
    moneyExchangerToken,
    moneyFormatterToken,
    phpAuthApiClientToken,
    referrerProviderToken,
    sharedTokens,
    shopifyUrlUtilsToken,
    socialLoginApiClientToken,
    tokenManagerToken,
    trackingApiServiceToken,
    translationsApiServiceToken,
    translationsAuthApiClientToken,
} from "./tokens";
import { ShopifyUrlUtils } from "./utils/shopify/ShopifyUrlUtils";

async function __gwMain() {
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_CONTAINER__ = container;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_TOKENS__ = sharedTokens;
    }

    // logger
    const globalLogger = new MinilogLogger("global");
    globalLogger.info("Growave main script is initialized!");
    container.registerInstance(globalLoggerToken, globalLogger);

    container.register(gwStorefrontAppInfoToken, {
        useFactory: instanceCachingFactory(() => {
            return {
                appName: __APP_NAME__,
                version: __VERSION__,
                inIframe: window.top !== window.self,
                designMode: !!Shopify.designMode,
                shopName: Shopify.shop,
                currentApp: CURRENT_APP_INSTAGRAM,
                tokenStorageKey: Shopify.designMode
                    ? "GW_TOKEN__DESIGN_MODE"
                    : "GW_TOKEN",
                appProxyPrefix: "/apps/ssw-instagram",
            };
        }),
    });

    akitaDevtools();

    // (utils) AppsStatusesProvider
    const appsStatusesProvider = new AppsStatusesProvider(globalLogger);
    container.registerInstance(AppsStatusesProvider, appsStatusesProvider);

    // (utils) location controller
    const locationController = new LocationController(window.location);
    container.registerInstance(locationControllerToken, locationController);

    const shopifyUrlUtils = new ShopifyUrlUtils(
        window.Shopify?.routes?.root || "/"
    );
    container.registerInstance(shopifyUrlUtilsToken, shopifyUrlUtils);

    container.register(fileUploaderAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/storefront-api/file-uploader-storefront/v2`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(socialLoginApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new ApiClientWithMetrics(
                new ApiClient(
                    `${
                        di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                    }/storefront-api/social-login-app/v2`,
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                    },
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(eventsCollectorToken),
                di.resolve(locationControllerToken),
                di.resolve(AnalyticsEventsFactory)
            );
        }),
    });

    container.register(eventsCollectorAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClient(
                    `${
                        di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                    }/events-collector-service/v2/`,
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                    },
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(commonAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new ApiClientWithMetrics(
                new ApiClient(
                    `${
                        di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                    }/storefront-api/common-storefront/v2`,
                    undefined,
                    {
                        [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                            gwStorefrontAppInfoToken
                        ).currentApp,
                    },
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(eventsCollectorToken),
                di.resolve(locationControllerToken),
                di.resolve(AnalyticsEventsFactory)
            );
        }),
    });

    container.register(translationsAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/translations-service/v2`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    container.register(apiClientToken, {
        useFactory: instanceCachingFactory(() => {
            return new ApiClient();
        }),
    });

    container.register(phpAuthApiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new AuthApiClient(
                new ApiClientWithMetrics(
                    new ApiClient(
                        `${
                            di.resolve(gwStorefrontAppInfoToken).appProxyPrefix
                        }/storefront-api/v2/`,
                        undefined,
                        {
                            [CURRENT_APP_QUERY_PARAMETER]: di.resolve(
                                gwStorefrontAppInfoToken
                            ).currentApp,
                        },
                        di.resolve(globalLoggerToken)
                    ),
                    di.resolve(eventsCollectorToken),
                    di.resolve(locationControllerToken),
                    di.resolve(AnalyticsEventsFactory)
                ),
                di.resolve(tokenManagerToken)
            );
        }),
    });

    // (utils) EventsCollector
    const eventsFactory = new AnalyticsEventsFactory(
        window.performance,
        navigator.userAgent,
        location.href,
        __APP_NAME__
    );
    const eventsCollector = new RxJSAnalyticsEventsCollector();
    container.registerInstance(eventsCollectorToken, eventsCollector);
    container.registerInstance(AnalyticsEventsFactory, eventsFactory);

    // localStorage
    container.register(localStorageToken, { useValue: localStorage });

    // token manager
    container.register(tokenManagerToken, {
        useFactory: instanceCachingFactory((di) => {
            return new GrowaveTokenManager(
                new RefreshTokenService(
                    di.resolve(socialLoginApiClientToken),
                    globalLogger
                ),
                di.resolve(localStorageToken),
                di.resolve(gwStorefrontAppInfoToken).tokenStorageKey,
                window.gw.browserTimeDiffMs,
                globalLogger
            );
        }),
    });

    const metricsCollectorService = new MetricsCollectorService(
        container.resolve(eventsCollectorAuthApiClientToken),
        globalLogger
    );

    const eventsSender = new AnalyticsEventsSender(
        eventsCollector,
        metricsCollectorService
    );
    eventsSender.start();

    // UTILS
    // (utils) history patcher
    const historyPatcher = new HistoryPatcher(
        [GP_GW_REFFERER],
        window.history,
        locationController
    );
    historyPatcher.patch();

    // (utils) fetchAndXHRPatcher
    const fetchAndXHRPatcher = new FetchAndXHRPatcher(globalLogger);
    fetchAndXHRPatcher.patch();
    container.registerInstance(fetchAndXhrPatcherToken, fetchAndXHRPatcher);

    // (utils) referrerProvider
    const referrerProvider = new ReferrerProvider();
    container.registerInstance(referrerProviderToken, referrerProvider);

    // ProductIdManager
    container.register(ProductIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductIdFromSdkProvider();
        }),
    });
    container.register(globalProductIdManagerToken, {
        useFactory: instanceCachingFactory(() => {
            const productIdManager = new ProductIdManager();
            productIdManager.registerProvider(
                new ProductIdFromAdditionalInfoProvider()
            );
            return productIdManager;
        }),
    });

    // ProductVariantsIdManager
    container.register(ProductVariantIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductVariantIdFromSdkProvider();
        }),
    });

    // (utils) CustomerInfoProvider
    const customerInfoProvider = new CustomerInfoFromJsonProvider(globalLogger);
    customerInfoProvider.readData();
    container.registerInstance(customerInfoProviderToken, customerInfoProvider);

    container.resolve(CurrentTokenChecker).check();

    // (utils) check service
    const checkService = new CheckService(
        container.resolve(socialLoginApiClientToken),
        container.resolve(tokenManagerToken),
        globalLogger
    );
    await lastValueFrom(checkService.check());

    // (utils) cart manager
    container.register(cartManagerCounterUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerCounterUpdatersContext();
        }),
    });

    container.register(cartManagerItemsUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerItemsUpdatersContext();
        }),
    });

    container.register(cartStoreToken, {
        useFactory: instanceCachingFactory(() => new CartStore()),
    });

    container.register(cartServiceToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartService(
                new ShopifyCartApiService(
                    new ApiClient("/"),
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(globalLoggerToken),
                di.resolve(cartManagerCounterUpdatersContextToken),
                di.resolve(cartManagerItemsUpdatersContextToken),
                di.resolve(cartStoreToken)
            );
        }),
    });

    container.register(cartQueryToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartQuery(di.resolve(cartStoreToken));
        }),
    });

    // (utils) cart listener
    const cartListener = new CartListener(globalLogger);
    cartListener.register(new SendFormListenerStrategy(globalLogger));
    cartListener.register(
        new FetchOrXHRPatcherListenerStrategy(globalLogger, fetchAndXHRPatcher)
    );
    container.registerInstance(cartListenerToken, cartListener);

    // (utils) currencyProvider
    const currencyProvider = new FromShopifyCurrencyCurrencyProvider(
        globalLogger
    );
    container.registerInstance(currencyProviderToken, currencyProvider);

    // (utils) growaveConfigurationProvider
    const growaveConfigurationProvider =
        new GrowaveConfigurationFromJsonProvider(globalLogger);
    const growaveConfiguration =
        growaveConfigurationProvider.getConfiguration();
    container.registerInstance(
        growaveConfigurationProviderToken,
        growaveConfigurationProvider
    );

    container.register(localizationInfoProviderToken, {
        useFactory: instanceCachingFactory(() => {
            return new SimpleLocalizationInfoProvider(
                growaveConfiguration.countryIsoCode,
                growaveConfiguration.languageIsoCode,
                growaveConfiguration.currencyIsoCode,
                growaveConfiguration.gwI18nLocale
            );
        }),
    });

    // (utils) TranslationsApiService
    const translationsApiService = new TranslationsApiService(
        container.resolve(translationsAuthApiClientToken),
        globalLogger
    );
    container.registerInstance(
        translationsApiServiceToken,
        translationsApiService
    );

    // (utils) I18n
    const i18n = new GwI18n(globalLogger, eventsCollector);
    const localizationInfoProvider = container.resolve(
        localizationInfoProviderToken
    );

    container.register(i18nCriticalLoaderFactoryToken, {
        useFactory: instanceCachingFactory((di) => {
            return new I18nCriticalLoaderFactory(
                di.resolve(localizationInfoProviderToken),
                di.resolve(translationsApiServiceToken),
                true
            );
        }),
    });

    i18n.registerLoader(
        "common.PostModal",
        new I18nApiLoader(
            "common.PostModal",
            localizationInfoProvider.getGwI18nLocale(),
            translationsApiService
        )
    );
    container.registerInstance(gwI18nToken, i18n);

    // (utils) moneyFormatter
    container.register(moneyFormatterToken, {
        useFactory: instanceCachingFactory(() => {
            return new MoneyFormatter(
                growaveConfiguration.moneyFormat,
                growaveConfiguration.moneyWithCurrencyFormat
            );
        }),
    });

    // (utils) GwCurrency
    container.register(GwCurrency, {
        useFactory: instanceCachingFactory(() => {
            const gwCurrency = new GwCurrency(globalLogger, new ApiClient());
            gwCurrency.loadRates();
            return gwCurrency;
        }),
    });

    // (utils) moneyExchanger
    container.register(moneyExchangerToken, {
        useFactory: instanceCachingFactory((di) => {
            if (growaveConfiguration.storefrontApiAccessToken) {
                return new FakeMoneyExchanger();
            }
            return new LegacyMoneyExchanger(
                growaveConfiguration.shopCurrency,
                di.resolve(GwCurrency)
            );
        }),
    });

    // (utils) HTMLSanitizer
    const HTMLSanitizer = new XSSHTMLSanitizer(globalLogger, {
        span: ["class", "id"],
        div: ["class", "id"],
        p: ["class", "id"],
        i: ["class", "id"],
        b: ["class", "id"],
        pre: ["class", "id"],
    });
    container.registerInstance(htmlSanitizerToken, HTMLSanitizer);

    // (utils) TrackingApiService
    const trackingApiService = new TrackingApiService(
        container.resolve(phpAuthApiClientToken),
        globalLogger
    );
    container.registerInstance(trackingApiServiceToken, trackingApiService);

    // (utils) GraphQLApiClient
    container.register(GraphQLApiClient, {
        useFactory: instanceCachingFactory(() => {
            if (growaveConfiguration.storefrontApiAccessToken) {
                return new GraphQLApiClient(
                    growaveConfiguration.storefrontApiAccessToken
                );
            }
            return null;
        }),
    });

    // SDK Module
    const sdkModule = new SdkModule();
    sdkModule.registerProviders(container, () => {
        throw new Error(
            "Initialization from SDK is not available in the Instagram App"
        );
    });

    const productsModule = new ProductsModule();
    productsModule.registerProviders(container);

    eventsCollector.pushEvent(eventsFactory.createChunkLoaded("main", -1));

    // Instagram
    container.register<InstagramApplicationConfigInterface>(
        InstagramApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-instagram-gallery-placeholder",
            },
        }
    );
    const startLoadingInstagramApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "InstagramApplication"*/ "@apps/instagram/InstagramApplication"
    ).then(({ InstagramApplication }) => {
        const duration = startLoadingInstagramApplicationTimer.ready();
        eventsCollector.pushEvent(
            eventsFactory.createChunkLoaded("InstagramApplication", duration)
        );
        const instagramApplication = container.resolve(InstagramApplication);
        instagramApplication.init();
    });

    // Tiktok
    container.register<TikTokApplicationConfigInterface>(
        TikTokApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-tiktok-gallery-placeholder",
            },
        }
    );
    const startLoadingTikTokApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "TikTokApplication" */ "@apps/tiktok/TikTokApplication"
    ).then(({ TikTokApplication }) => {
        const duration = startLoadingTikTokApplicationTimer.ready();
        eventsCollector.pushEvent(
            eventsFactory.createChunkLoaded("TikTokApplication", duration)
        );

        const tiktokApplication = container.resolve(TikTokApplication);
        tiktokApplication.init();
    });
}

void __gwMain();
